<script>
import background from '../assets/pre-cybermonday/background.png'
import logo from '../assets/pre-cybermonday/logo.png'
import countdownMixin from '../mixins/countdown-mixin'

export default {
	mixins: [countdownMixin('cybermonday')],
	metaInfo() {
		return {
			title: 'Cybermonday',
			meta: [{ vmid: 'cy', name: 'description', content: 'Cybermonday' }],
		}
	},
	data() {
		return {
			name: '',
			email: '',

			validation: {},
			state: 'pending',
			loading: false,

			background,
			logo,
		}
	},
	methods: {
		async submit() {
			const data = {
				name: this.name,
				email: this.email,
			}

			return await this.$shopApi.post({
				url: '/user-forms/cybermonday',
				data: { data },
				loading: (v) => {
					this.loading = v
				},
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	watch: {
		secondsLeft() {
			if (!this.secondsLeft) return this.$router.replace({ name: 'cybermonday' })
		},
	},
	mounted() {
		if (!this.secondsLeft) return this.$router.replace({ name: 'cybermonday' })
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div class="cybermonday" :style="`background-image: url(${background})`">
		<div class="cyber-form" :class="$b.m && 'mx-4'">
			<div :class="!$b.m && 'd-flex'" class="justify-start align-center mb-3">
				<div class="mx-7">
					<Media class="mx-auto" :width="186" :src="logo" :aspectRatio="249 / 203" />
				</div>
				<div :class="$b.m && 'text-center'">
					<div class="font-6 mb-4">Cybermonday en Garbarino</div>
					<div v-if="!$b.m">
						Enterate <b>antes que nadie las ofertas</b> y los productos <br />
						disponibles. Suscribite ahora y <b>conseguí beneficios exclusivos</b><br />
						para el próximo <b>CyberMonday</b> en <b>Garbarino</b>.
					</div>
					<div v-else>
						Enterate <b>antes que nadie las ofertas</b> y los productos disponibles. Suscribite ahora y
						<b>conseguí beneficios exclusivos</b> para el próximo <b>CyberMonday</b> en
						<b>Garbarino</b>.
					</div>
				</div>
			</div>
			<div :class="!$b.m && 'd-flex justify'">
				<div>
					<div class="countdown" :class="$b.m && 'justify-center'">
						<div class="countdown-item">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ days }}</div>
							<div class="countdown-unit">Días</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ hours }}</div>
							<div class="countdown-unit">Hs</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ minutes }}</div>
							<div class="countdown-unit">Min</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ seconds }}</div>
							<div class="countdown-unit">Seg</div>
						</div>
					</div>
					<div class="text-center mt-3">6, 7 y 8 de Noviembre</div>
				</div>
				<form @submit.prevent="submit" class="pt-2" v-if="state !== 'success'">
					<v-row>
						<v-col cols="12" md="6">
							<TextField
								v-model="name"
								outlined
								filled
								rounded
								dense
								singleLine
								label="Nombre"
								:error-messages="validation.name"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<TextField
								v-model="email"
								outlined
								filled
								rounded
								dense
								singleLine
								label="Correo Electrónico"
								:error-messages="validation.email"
							/>
						</v-col>
					</v-row>
					<div class="d-flex justify-center mt-4">
						<Button
							rounded
							color="linear-gradient(0deg, rgba(119, 50, 232, 1) 0%, rgba(88, 240, 240, 1) 100%)"
							class="btn-cybermonday"
							type="submit"
							:loading="loading"
							>Suscribirme Ahora!</Button
						>
					</div>
				</form>
				<div v-else>
					<div class="font-2 px-4 pb-4 text-center">
						<div class="pb-3 font-5">Gracias por Suscribirte</div>
						<div>Pronto te enviaremos todas nuestras ofertas</div>
						<Button
							rounded
							color="linear-gradient(0deg, rgba(119, 50, 232, 1) 0%, rgba(88, 240, 240, 1) 100%)"
							class="btn-cybermonday mt-4"
							@click="$shop.goShop()"
						>
							Volver a la tienda
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
::v-deep .v-input__slot {
	background: white !important;
	padding-left: 8px !important;
	font-size: 13px;
	min-height: 35px !important;

	& label {
		line-height: 15px;
	}

	& label {
		font-size: 13px;
	}
}

.cybermonday {
	background-position: center center;
	background-size: cover;
	min-height: 600px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	position: relative;
}
.cybermonday::before {
	content: '';
	background-color: rgb(0 0 0 / 0.2);
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}
.cyber-form {
	background-color: #251c4dee;
	width: 100%;
	max-width: 700px;
	height: auto;
	padding: 20px;
	border-radius: 10px;
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	z-index: 1;
}
.cyber-logo {
	width: 186px;
	height: auto;
}

.countdown {
	display: flex;
	align-items: center;
}

.countdown-item {
	text-align: center;
	margin: 0 10px;
	background: rgb(119, 50, 232);
	background: linear-gradient(0deg, rgba(119, 50, 232, 1) 0%, rgba(88, 240, 240, 1) 100%);
	color: white;
	padding: 5px;
	width: 40px;
	border-radius: 4px;
	position: relative;
}
.countdown-item .countdown-border {
	border: 1px solid #fff;
	border-radius: 4px;
	width: 40px;
	height: 53px;
	position: absolute;
	top: 3px;
	left: -3px;
}
.countdown-item .countdown-amount {
	font-size: 1.5em;
}
.countdown-item .countdown-unit {
	font-size: 0.4em;
}
.btn-cybermonday {
	background: rgb(119, 50, 232);
	background: linear-gradient(0deg, rgba(119, 50, 232, 1) 0%, rgba(88, 240, 240, 1) 100%);
	border-radius: 20px;
	transition: background 1s ease;
}
.btn.btn-cybermonday:hover {
	background: linear-gradient(0deg, rgba(88, 240, 240, 1) 0%, rgba(119, 50, 232, 1) 100%);
}
.c-margin {
	margin-left: 2px;
	margin-right: 2px;
}
</style>
