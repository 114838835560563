import { get } from 'vuex-pathify'

export default (key) => ({
	data() {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
		}
	},
	computed: {
		secondsLeft: get(key + '/secondsLeft'),
	},
	methods: {
		doTimeout() {
			if (!this.secondsLeft) {
				this.days = '00'
				this.hours = '00'
				this.minutes = '00'
				this.seconds = '00'
			} else {
				let timeDifference = this.secondsLeft
				let days = Math.floor(timeDifference / (60 * 60 * 24))
				timeDifference -= days * (60 * 60 * 24)

				let hours = Math.floor(timeDifference / (60 * 60))
				timeDifference -= hours * (60 * 60)

				let minutes = Math.floor(timeDifference / 60)
				timeDifference -= minutes * 60

				if (days < 10) days = '0' + days
				if (hours < 10) hours = '0' + hours
				if (minutes < 10) minutes = '0' + minutes
				if (timeDifference < 10) timeDifference = '0' + timeDifference

				this.days = days
				this.hours = hours
				this.minutes = minutes
				this.seconds = timeDifference
			}
		},
	},
	watch: {
		secondsLeft() {
			this.doTimeout()
		},
	},
	mounted() {
		this.doTimeout()
	},
})
