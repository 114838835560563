<script>
import background from '../assets/pre-hotsale/background.jpg'
import logo from '../assets/pre-hotsale/logo.png'
import logoGarba from '../assets/pre-hotsale/logo-garbarino.png'

import countdownMixin from '../mixins/countdown-mixin'

export default {
	mixins: [countdownMixin('hotsale')],
	metaInfo() {
		return {
			title: 'Hot Sale',
			meta: [{ vmid: 'hs', name: 'description', content: 'Hot Sale' }],
		}
	},
	data() {
		return {
			name: '',
			email: '',

			validation: {},
			state: 'pending',
			loading: false,

			background,
			logo,
			logoGarba,
		}
	},
	methods: {
		async submit() {
			const data = {
				name: this.name,
				email: this.email,
			}

			return await this.$shopApi.post({
				url: '/user-forms/hotsale',
				data: { data },
				loading: (v) => {
					this.loading = v
				},
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	watch: {
		secondsLeft() {
			if (!this.secondsLeft) return this.$router.replace('/hotsale')
		},
	},
	mounted() {
		if (!this.secondsLeft) return this.$router.replace('/hotsale')
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div class="hotsale" :style="`background-image: url(${background})`">
		<div class="cyber-form" :class="$b.m && 'mx-4'">
			<div :class="!$b.m && 'd-flex'" class="justify-start align-center mb-3">
				<div class="mx-7">
					<Media class="mx-auto" :width="186" :src="logo" :aspectRatio="249 / 203" />
				</div>
				<div :class="$b.m && 'text-center'">
					<div class="font-6 mb-4 coda-extra-bold d-flex align-center" :class="$b.m && 'flex-column'">
						<div class="text-red-garba" style="padding-top: 2.1px">Hot Sale en</div>
						<Media :src="logoGarba" width="225px" />
					</div>
					<div v-if="!$b.m" class="coda-regular hotsale-pink">
						Enterate <b>antes que nadie las ofertas</b> y los productos <br />
						disponibles. Suscribite ahora y <b>conseguí beneficios exclusivos</b><br />
						para el próximo <b>Hot Sale</b> en <b>Garbarino</b>.
					</div>
					<div v-else class="coda-regular hotsale-pink">
						Enterate <b>antes que nadie las ofertas</b> y los productos disponibles. Suscribite ahora y
						<b>conseguí beneficios exclusivos</b> para el próximo <b>Hot Sale</b> en <b>Garbarino</b>.
					</div>
				</div>
			</div>
			<div :class="!$b.m && 'd-flex justify'">
				<div>
					<div class="countdown coda-regular" :class="$b.m && 'justify-center'">
						<div class="countdown-item">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ days }}</div>
							<div class="countdown-unit">Días</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ hours }}</div>
							<div class="countdown-unit">Hs</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ minutes }}</div>
							<div class="countdown-unit">Min</div>
						</div>
						<div class="countdown-item countdown-border">
							<div class="countdown-border"></div>
							<div class="countdown-amount">{{ seconds }}</div>
							<div class="countdown-unit">Seg</div>
						</div>
					</div>
					<div class="text-center mt-3 coda-extra-bold font-4 text-shadow">
						<span class="hotsale-green"> 13, 14 y 15 </span>
						<span class="hotsale-pink">de Mayo</span>
					</div>
				</div>
				<form @submit.prevent="submit" class="pt-2" v-if="state !== 'success'">
					<v-row>
						<v-col cols="12" md="6">
							<TextField
								v-model="name"
								outlined
								filled
								rounded
								dense
								singleLine
								label="Nombre"
								:error-messages="validation.name"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<TextField
								v-model="email"
								outlined
								filled
								rounded
								dense
								singleLine
								label="Correo Electrónico"
								:error-messages="validation.email"
							/>
						</v-col>
					</v-row>
					<div class="d-flex justify-center mt-4">
						<Button rounded color="#f458aa" class="btn-hotsale" type="submit" :loading="loading"
							><span class="coda-extra-bold">Suscribirme Ahora!</span></Button
						>
					</div>
				</form>
				<div v-else>
					<div class="font-2 px-4 pb-4 text-center hotsale-pink coda-extra-bold">
						<div class="pb-3 font-5">Gracias por Suscribirte</div>
						<div>Pronto te enviaremos todas nuestras ofertas</div>
						<Button rounded color="#f458aa" class="btn-hotsale mt-4" @click="$shop.goShop()">
							<span class="coda-extra-bold">Volver a la tienda</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Coda:wght@400;800&display=swap');
$hotsale-green: #37dd8a;
$hotsale-pink: #f458aa;
$hotsale-yellow: #f4da58;

.coda-extra-bold {
	font-family: 'Coda', system-ui;
	font-weight: 800;
	font-style: normal;
}
.coda-regular {
	font-family: 'Coda', system-ui;
	font-weight: 400;
	font-style: normal;
}

.hotsale-yellow {
	color: #f4da58;
}
.text-shadow {
	text-shadow: 1px 2px 0 #000;
}
.hotsale-pink {
	color: #f458aa;
}

.text-red-garba {
	color: #e42628;
}

.hotsale-green {
	color: #37dd8a;
}

.hotsale-grey {
	color: #555555;
}

::v-deep .v-input__slot {
	background: white !important;
	padding-left: 8px !important;
	font-size: 13px;
	min-height: 35px !important;

	& label {
		line-height: 15px;
	}

	& label {
		font-size: 13px;
	}
}

.hotsale {
	background-position: center center;
	background-size: cover;
	min-height: 600px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	position: relative;
}
.hotsale::before {
	content: '';
	background-color: rgb(0 0 0 / 0.2);
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}
.cyber-form {
	background-color: #feedd6;
	width: 100%;
	max-width: 700px;
	height: auto;
	padding: 20px;
	border-radius: 10px;
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.3);
	z-index: 1;
}
.cyber-logo {
	width: 186px;
	height: auto;
}

.countdown {
	display: flex;
	align-items: center;
}

.countdown-item {
	text-align: center;
	margin: 0 10px;
	background: $hotsale-green;
	color: #fff;
	padding: 5px;
	width: 40px;
	border-radius: 4px;
	position: relative;
}
.countdown-item .countdown-border {
	border: 1px solid #fff;
	border-radius: 4px;
	width: 40px;
	height: 53px;
	position: absolute;
	top: 3px;
	left: -3px;
}
.countdown-item .countdown-amount {
	font-size: 1.5em;
}
.countdown-item .countdown-unit {
	font-size: 0.8em;
}
.btn-hotsale {
	border-radius: 20px;
	transition: background 1s ease;
}

.c-margin {
	margin-left: 2px;
	margin-right: 2px;
}
</style>
